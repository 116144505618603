export const TranslationConstants = {
  i18n_ATHLETES: "ATHLETES",
  i18n_REFEREES: "REFEREES",
  i18n_COMPETITIONS: "COMPETITIONS",
  i18n_PROFILE: "PROFILE",
  i18n_RESERVED_AREA: "RESERVED_AREA",
  i18n_LOGIN: "LOGIN",
  i18n_EMAIL: "EMAIL",
  i18n_RESTART: "RESTART",
  i18n_PAUSE: "PAUSE",
  i18n_PLAY: "PLAY",
  i18n_PASSWORD: "PASSWORD",
  i18n_INSERT_A_VALID_EMAIL: "INSERT_A_VALID_EMAIL",
  i18n_EMAIL_OR_PASSWORD_WRONG: "EMAIL_OR_PASSWORD_WRONG",
  i18n_LOGOUT: "LOGOUT",
  i18n_SOMETHING_WENT_WRONG: "SOMETHING_WENT_WRONG",
  i18n_PAGE_NOT_FOUND: "PAGE_NOT_FOUND",
  i18n_UNAUTHORIZED_MESSAGE: "UNAUTHORIZED_MESSAGE",
  i18n_BACK_TO_HOME: "BACK_TO_HOME",
  i18n_RELOAD_PAGE: "RELOAD_PAGE",
  i18n_LOADING: "LOADING",
  i18n_FILE_NAME: "FILE_NAME",
  i18n_UPLOAD_TYPE_SELECTOR: "UPLOAD_TYPE_SELECTOR",
  i18n_FROM_FILE: "FROM_FILE",
  i18n_FROM_URL: "FROM_URL",
  i18n_YOUTUBE_URL: "YOUTUBE_URL",
  i18n_INSERT_A_VALID_URL: "INSERT_A_VALID_URL",
  i18n_OFFSET: "OFFSET",
  i18n_VIDEO: "VIDEO",
  i18n_VIDEO_FILE: "VIDEO_FILE",
  i18n_CSV_FILE: "CSV_FILE",
  i18n_VIDEO_FILES: "VIDEO_FILES",
  i18n_MANDATORY_FIELD: "MANDATORY_FIELD",
  i18n_UPLOAD: "UPLOAD",
  i18n_OK: "OK",
  i18n_CANCEL: "CANCEL",
  i18n_UPLOAD_VIDEO: "UPLOAD_VIDEO",
  i18n_UPLOAD_CSV: "UPLOAD_CSV",
  i18n_UPLOAD_VIDEOS: "UPLOAD_VIDEOS",
  i18n_UPLOAD_MANY_VIDEOS: "UPLOAD_MANY_VIDEOS",
  i18n_VIEW: "VIEW",
  i18n_UPDATE: "UPDATE",
  i18n_POPULATE: "POPULATE",
  i18n_TAG_MATCH: "TAG_MATCH",
  i18n_MATCH_EVENT_ANALYSIS: "MATCH_EVENT_ANALYSIS",
  i18n_FORBIDDEN_ERROR_MESSAGE: "FORBIDDEN_ERROR_MESSAGE",
  i18n_EVENT_EXPLORE: "EVENT_EXPLORE",
  i18n_REMOVE_FILTERS: "REMOVE_FILTERS",
  i18n_EVENTS: "EVENTS",
  i18n_UPLOADING_THE_FILE: "UPLOADING_THE_FILE",
  i18n_UPLOAD_COMPLETED_SUCCESSFULLY: "UPLOAD_COMPLETED_SUCCESSFULLY",
  i18n_OPERATION_COMPLETED_SUCCESSFULLY: "OPERATION_COMPLETED_SUCCESSFULLY",
  i18n_CSV_UPLOAD_MESSAGE: "CSV_UPLOAD_MESSAGE",
  i18n_EVENT_DELETED_SUCCESSFULLY: "EVENT_DELETED_SUCCESSFULLY",
  i18n_EVENTS_DELETED_SUCCESSFULLY: "EVENTS_DELETED_SUCCESSFULLY",
  i18n_VIDEO_DELETED_SUCCESSFULLY: "VIDEO_DELETED_SUCCESSFULLY",
  i18n_ALL: "ALL",
  i18n_COMPETITION: "COMPETITION",
  i18n_AGE: "AGE",
  i18n_POINTS: "POINTS",
  i18n_WEIGHT: "WEIGHT",
  i18n_ROUND: "ROUND",
  i18n_FILTER: "FILTER",
  i18n_RESET: "RESET",
  i18n_ADVANCED_SEARCH: "ADVANCED_SEARCH",
  i18n_EXPORT_CSV: "EXPORT_CSV",
  i18n_DOWNLOAD_CSV: "DOWNLOAD_CSV",
  i18n_WAIT_CSV_GENERATION_MESSAGE: "WAIT_CSV_GENERATION_MESSAGE",
  i18n_MATCHES: "MATCHES",
  i18n_LAMP: "LAMP",
  i18n_TAG_ALL: "TAG_ALL",
  i18n_MATCH: "MATCH",
  i18n_TAGS: "TAGS",
  i18n_EXPLORE: "EXPLORE",
  i18n_EVENT_TAGGING: "EVENT_TAGGING",
  i18n_VIDEO_ANALYSIS: "VIDEO_ANALYSIS",
  i18n_CMS: "CMS",
  i18n_DASHBOARD: "DASHBOARD",
  i18n_VS: "VS",
  i18n_SHOW: "SHOW",
  i18n_ELEMENTS: "ELEMENTS",
  i18n_MISSING_DATA: "MISSING_DATA",
  i18n_MISSING_SYMBOL: "MISSING_SYMBOL",
  i18n_SEARCH: "SEARCH",
  i18n_NO_FILTER: "NO_FILTER",
  i18n_BOARD_NUMBER: "BOARD_NUMBER",
  i18n_YES: "YES",
  i18n_NO: "NO",
  i18n_RESULT: "RESULT",
  i18n_INFO: "INFO",
  i18n_BODY_PART: "BODY_PART",
  i18n_MEDICAL: "MEDICAL",
  i18n_ACTIONS: "ACTIONS",
  i18n_VIDEO_AVAILABLE: "VIDEO_AVAILABLE",
  i18n_VIDEO_NOT_AVAILABLE: "VIDEO_NOT_AVAILABLE",
  i18n_TAGGED: "TAGGED",
  i18n_NOT_TAGGED: "NOT_TAGGED",
  i18n_DATA_NOT_FOUND: "DATA_NOT_FOUND",
  i18n_TBD: "TBD",
  i18n_VIDEO_UPLOADING: "VIDEO_UPLOADING",
  i18n_VIDEO_TAGGING: "VIDEO_TAGGING",
  i18n_START_HAJIME: "START_HAJIME",
  i18n_END_HAJIME: "END_HAJIME",
  i18n_START_SEQUENCE: "START_SEQUENCE",
  i18n_END_SEQUENCE: "END_SEQUENCE",
  i18n_START_MATTE: "START_MATTE",
  i18n_END_MATTE: "END_MATTE",
  i18n_START_MEDICAl_INTERRUPTION: "START_MEDICAL_INTERRUPTION",
  i18n_END_MEDICAL_INTERRUPTION: "END_MEDICAL_INTERRUPTION",
  i18n_START_NE_WAZA: "START_NE_WAZA",
  i18n_END_NE_WAZA: "END_NE_WAZA",
  i18n_SHIDO: "SHIDO",
  i18n_LATE_PENALTY: "LATE_PENALTY",
  i18n_LATE_PENALTY_SHORT: "LATE_PENALTY_SHORT",
  i18n_HANSOKUMAKE: "HANSOKUMAKE",
  i18n_VICTORY: "VICTORY",
  i18n_NO_SCORE: "NO_SCORE",
  i18n_KINZA: "KINZA",
  i18n_YUKO: "YUKO",
  i18n_SCORE: "SCORE",
  i18n_WAZA_ARI: "WAZA_ARI",
  i18n_IPPON: "IPPON",
  i18n_LEFT: "LEFT",
  i18n_RIGHT: "RIGHT",
  i18n_STANCES_AND_SEQUENCES: "STANCES_AND_SEQUENCES",
  i18n_SEGMENT_NUMBER: "SEGMENT_NUMBER",
  i18n_SEQUENCE_NUMBER: "SEQUENCE_NUMBER",
  i18n_DELETE: "DELETE",
  i18n_EVALUATION_SWITCH: "EVALUATION_SWITCH",
  i18n_SELECT_ALL_RELATED_ELEMENTS: "SELECT_ALL_RELATED_ELEMENTS",
  i18n_DESELECT_ALL_RELATED_ELEMENTS: "DESELECT_ALL_RELATED_ELEMENTS",
  i18n_DESELECT_ALL: "DESELECT_ALL",
  i18n_DELETE_VIDEO: "DELETE_VIDEO",
  i18n_DELETE_QUESTION: "DELETE_QUESTION",
  i18n_DELETE_SELECTED_EVENTS_QUESTION: "DELETE_SELECTED_EVENTS_QUESTION",
  i18n_NAME: "NAME",
  i18n_TIME: "TIME",
  i18n_DURATION: "DURATION",
  i18n_REFEREE: "REFEREE",
  i18n_COACH: "COACH",
  i18n_STANCE_WHITE: "STANCE_WHITE",
  i18n_STANCE_BLUE: "STANCE_BLUE",
  i18n_PERIOD: "PERIOD",
  i18n_CREATED_SUCCESSFULLY_MESSAGE: "CREATED_SUCCESSFULLY_MESSAGE",
  i18n_UPDATED_SUCCESSFULLY_MESSAGE: "UPDATED_SUCCESSFULLY_MESSAGE",
  i18n_DELETED_SUCCESSFULLY_MESSAGE: "DELETED_SUCCESSFULLY_MESSAGE",
  i18n_UPDATED_SUCCESSFULLY_MANY_MESSAGE: "UPDATED_SUCCESSFULLY_MANY_MESSAGE",
  i18n_ATTACKING_ATHLETE: "ATTACKING_ATHLETE",
  i18n_DEFENDING_ATHLETE: "DEFENDING_ATHLETE",
  i18n_GENERAL: "GENERAL",
  i18n_SWITCH: "SWITCH",
  i18n_SWITCH_2: "SWITCH_2",
  i18n_COACH_CARD: "COACH_CARD",
  i18n_PENALTY: "PENALTY",
  i18n_MEDICAL_INTERRUPTION: "MEDICAL_INTERRUPTION",
  i18n_WHITE: "WHITE",
  i18n_WHITE_ATHLETE: "WHITE_ATHLETE",
  i18n_BLUE_ATHLETE: "BLUE_ATHLETE",
  i18n_BLUE: "BLUE",
  i18n_NE_WAZA: "NE_WAZA",
  i18n_NE_WAZA_FAMILY: "NE_WAZA_FAMILY",
  i18n_TACHI_WAZA_FAMILY: "TACHI_WAZA_FAMILY",
  i18n_OTHER: "OTHER",
  i18n_GOLDEN_SCORE: "GOLDEN_SCORE",
  i18n_DELETE_SELECTED_EVENTS: "DELETE_SELECTED_EVENTS",
  i18n_OVERTURN: "OVERTURN",
  i18n_TOKETA: "TOKETA",
  i18n_TACHI_WAZA: "TACHI_WAZA",
  i18n_VIDEO_REVIEW: "VIDEO_REVIEW",
  i18n_SOREMADE: "SOREMADE",
  i18n_LOSER: "LOSER",
  i18n_WINNER: "WINNER",
  i18n_OUTCOME: "OUTCOME",
  i18n_NAGE_WAZA: "NAGE_WAZA",
  i18n_COMBINATION: "COMBINATION",
  i18n_COUNTERATTACK: "COUNTERATTACK",
  i18n_DIRECTION: "DIRECTION",
  i18n_TATAMI_AREA: "TATAMI_AREA",
  i18n_ATTITUDE: "ATTITUDE",
  i18n_ATTACKER_LATERALITY: "ATTACKER_LATERALITY",
  i18n_DEFENDER_LATERALITY: "DEFENDER_LATERALITY",
  i18n_ATTACKER_POSTURE: "ATTACKER_POSTURE",
  i18n_DEFENDER_POSTURE: "DEFENDER_POSTURE",
  i18n_NE_WAZA_COMBO: "NE_WAZA_COMBO",
  i18n_NAGE_WAZA_COMBO: "NAGE_WAZA_COMBO",
  i18n_NAGE_WAZA_COUNTER: "NAGE_WAZA_COUNTER",
  i18n_TRANSITION: "TRANSITION",
  i18n_WORK_POSITION: "WORK_POSITION",
  i18n_FIRST_ATTEMPT: "FIRST_ATTEMPT",
  i18n_SWITCHES: "SWITCHES",
  i18n_SWITCHES_2: "SWITCHES_2",
  i18n_PENALTIES: "PENALTIES",
  i18n_PENALTY_KINDS: "PENALTY_KINDS",
  i18n_TECHNICAL: "TECHNICAL",
  i18n_DEPTH: "DEPTH",
  i18n_BODY_POSITION: "BODY_POSITION",
  i18n_POINT_LATERALITY: "POINT_LATERALITY",
  i18n_KUMI_KATA: "KUMI_KATA",
  i18n_POSTURE: "POSTURE",
  i18n_POSTURES: "POSTURES",
  i18n_COMBO: "COMBO",
  i18n_COUNTER: "COUNTER",
  i18n_POINT_SWITCH_PENALTY: "POINT_SWITCH_PENALTY",
  i18n_KATAME_WAZA_COMBO: "KATAME_WAZA_COMBO",
  i18n_ATTACKER: "ATTACKER",
  i18n_DEFENDER: "DEFENDER",
  i18n_GRID_ATTITUDE_AREA: "GRID_ATTITUDE_AREA",
  i18n_ACTION_AREA: "ACTION_AREA",
  i18n_DIRECTION_TECHNIQUE: "DIRECTION_TECHNIQUE",
  i18n_ATTACKER_DIRECTION: "ATTACKER_DIRECTION",
  i18n_KATAME_WAZA: "KATAME_WAZA",
  i18n_GRIP_ATTITUDE_AREA: "GRIP_ATTITUDE_AREA",
  i18n_ADDITIONAL_TOOLS: "ADDITIONAL_TOOLS",
  i18n_ATTACKER_KUMI_KATA: "ATTACKER_KUMI_KATA",
  i18n_NOME_CSV: "NOME_CSV",
  i18n_POSIZIONE_CSV: "POSIZIONE_CSV",
  i18n_DURATA_CSV: "DURATA_CSV",
  i18n_POINT_CSV: "POINT_CSV",
  i18n_KINZA_CSV: "KINZA_CSV",
  i18n_NAGE_WAZA_CSV: "NAGE_WAZA_CSV",
  i18n_DIRECTION_CSV: "DIRECTION_CSV",
  i18n_KUMIKATA_CSV: "KUMIKATA_CSV",
  i18n_COMBINATION_CSV: "COMBINATION_CSV",
  i18n_COUNTER_ATTACK_CSV: "COUNTER_ATTACK_CSV",
  i18n_ACTION_AREA_CSV: "ACTION_AREA_CSV",
  i18n_ATTITUDE_CSV: "ATTITUDE_CSV",
  i18n_GOOD_POINT_LATERALITY_CSV: "GOOD_POINT_LATERALITY_CSV",
  i18n_GOOD_POINT_POSTURE_CSV: "GOOD_POINT_POSTURE_CSV",
  i18n_WEAK_POINT_LATERALITY_CSV: "WEAK_POINT_LATERALITY_CSV",
  i18n_WEAK_POINT_POSTURE_CSV: "WEAK_POINT_POSTURE_CSV",
  i18n_GOOD_POINT_CSV: "GOOD_POINT_CSV",
  i18n_WEAK_POINT_CSV: "WEAK_POINT_CSV",
  i18n_NE_WAZA_CSV: "NE_WAZA_CSV",
  i18n_TRANSITION_CSV: "TRANSITION_CSV",
  i18n_WORK_POSITION_CSV: "WORK_POSITION_CSV",
  i18n_FIRST_ATTEMPT_CSV: "FIRST_ATTEMPT_CSV",
  i18n_NW_COMBO_CSV: "NW_COMBO_CSV",
  i18n_REFEREE_CSV: "REFEREE_CSV",
  i18n_PENALTY_CSV: "PENALTY_CSV",
  i18n_PENALTY_KIND_CSV: "PENALTY_KIND_CSV",
  i18n_SWITCH_CSV: "SWITCH_CSV",
  i18n_SWITCH_2_CSV: "SWITCH_2_CSV",
  i18n_HAJIME_CSV: "HAJIME_CSV",
  i18n_BREAK_CSV: "BREAK_CSV",
  i18n_SEGMENT_CSV: "SEGMENT_CSV",
  i18n_SEQUENCE_CSV: "SEQUENCE_CSV",
  i18n_STANCE_WHITE_CSV: "STANCE_WHITE_CSV",
  i18n_STANCE_BLUE_CSV: "STANCE_BLUE_CSV",
  i18n_GOLDEN_CSV: "GOLDEN_CSV",
  i18n_WINNER_CSV: "WINNER_CSV",
  i18n_LOSER_CSV: "LOSER_CSV",
  i18n_OUTCOME_CSV: "OUTCOME_CSV",
  i18n_FAMILIES_CSV: "FAMILIES_CSV",
  i18n_MEDICAL_CSV: "MEDICAL_CSV",
  i18n_COACH_CSV: "COACH_CSV",
  i18n_FILE_ID_CSV: "FILE_ID_CSV",
  i18n_COLLECTION_ID_CSV: "COLLECTION_ID_CSV",
  i18n_FILENAME_CSV: "FILENAME_CSV",
  i18n_LIVELLOEVENTO_CSV: "LIVELLOEVENTO_CSV",
  i18n_LUOGOEVENTO_CSV: "LUOGOEVENTO_CSV",
  i18n_CLASSEETA_CSV: "CLASSE_ETA_CSV",
  i18n_ANNO_CSV: "ANNO_CSV",
  i18n_CATEGORIADIPESO_CSV: "CATEGORIADIPESO_CSV",
  i18n_TABELLONE_CSV: "TABELLONE_CSV",
  i18n_DRAWID_CSV: "DRAWID_CSV",
  i18n_ATLETABIANCO_CSV: "ATLETABIANCO_CSV",
  i18n_NAZIONEBIANCO_CSV: "NAZIONEBIANCO_CSV",
  i18n_ATLETABLU_CSV: "ATLETABLU_CSV",
  i18n_NAZIONEBLU: "NAZIONEBLU_CSV",
  i18n_ARBITRO_CSV: "ARBITRO_CSV",
  i18n_NAZIONEARBITRO_CSV: "NAZIONEARBITRO_CSV",
  i18n_VITTORIA_CSV: "VITTORIA_CSV",
  i18n_ADD_ATHLETE: "ADD_ATHLETE",
  i18n_MALE: "MALE",
  i18n_FEMALE: "FEMALE",
  i18n_COUNTRY: "COUNTRY",
  i18n_GENDER: "GENDER",
  i18n_IMAGE: "IMAGE",
  i18n_SURNAME: "SURNAME",
  i18n_CODE: "CODE",
  i18n_BIRTHDATE: "BIRTHDATE",
  i18n_AGE_CLASS: "AGE_CLASS",
  i18n_WEIGHT_CLASS: "WEIGHT_CLASS",
  i18n_WEIGHT_CLASSES: "WEIGHT_CLASSES",
  i18n_CURRENT_AGE_CLASS: "CURRENT_AGE_CLASS",
  i18n_CURRENT_WEIGHT_CLASS: "CURRENT_WEIGHT_CLASS",
  i18n_ADD_REFEREE: "ADD_REFEREE",
  i18n_ADD_MATCH: "ADD_MATCH",
  i18n_ADD_COMPETITION: "ADD_COMPETITION",
  i18n_CITY: "CITY",
  i18n_SEASON: "SEASON",
  i18n_START_DATE: "START_DATE",
  i18n_END_DATE: "END_DATE",
  i18n_COMPETITION_LEVEL: "COMPETITION_LEVEL",
  i18n_DATE: "DATE",
  i18n_UPDATE_ATHLETE: "UPDATE_ATHLETE",
  i18n_CREATE_ATHLETE: "CREATE_ATHLETE",
  i18n_UPDATE_MATCH: "UPDATE_MATCH",
  i18n_CREATE_MATCH: "CREATE_MATCH",
  i18n_UPDATE_COMPETITION: "UPDATE_COMPETITION",
  i18n_CREATE_COMPETITION: "CREATE_COMPETITION",
  i18n_UPDATE_REFEREE: "UPDATE_REFEREE",
  i18n_CREATE_REFEREE: "CREATE_REFEREE",
  i18n_CREATE: "CREATE",
  i18n_DUPLICATION_ERROR_MESSAGE: "DUPLICATION_ERROR_MESSAGE",
  i18n_CLOSE: "CLOSE",
  i18n_BACK_TO_CMS: "BACK_TO_CMS",
  i18n_BACK_TO_PREVIOUS_PAGE: "BACK_TO_PREVIOUS_PAGE",
  i18n_ATHLETE: "ATHLETE",
  i18n_YYYY_MM_DD: "YYYY_MM_DD",
  i18n_MATCHES_TO_CREATE: "MATCHES_TO_CREATE",
  i18n_MATCHES_CREATED: "MATCHES_CREATED",
  i18n_STATS: "STATS",
  i18n_STATUS: "STATUS",
  i18n_CREATED: "CREATED",
  i18n_TO_CREATE: "TO_CREATE",
  i18n_SAVE: "SAVE",
  i18n_MATCHES_SUCCESSFULLY_MESSAGE: "MATCHES_CREATED_SUCCESSFULLY_MESSAGE",
  i18n_SAVE_ALL: "SAVE_ALL",
} as const
